import React, { useState, useContext, useEffect } from "react";
import MyContext from "../../context/data/myContext";
import { Link } from "react-router-dom";

function AddLib() {
  const context = useContext(MyContext);
  const { books, setBooks, addBook, ca1 } = context;

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [expandedPackages, setExpandedPackages] = useState({});
  const [expandedSubpackages, setExpandedSubpackages] = useState({});

  useEffect(() => {
    console.log(selectedCategories);
    setBooks({ ...books, category: selectedCategories.join(", ") });
    // eslint-disable-next-line
  }, [selectedCategories]);

  const groupedCategories = ca1.reduce((acc, category) => {
    const packageValue = category.paquete || "Categorías";
    const subpackageValue = category.subpaquete || "Desplegar Categorías";

    if (!acc[packageValue]) {
      acc[packageValue] = {};
    }

    if (!acc[packageValue][subpackageValue]) {
      acc[packageValue][subpackageValue] = [];
    }

    acc[packageValue][subpackageValue].push(category);

    return acc;
  }, {});

  const handleCheckboxChange = (category) => {
    setSelectedCategories((prevSelected) => {
      if (prevSelected.includes(category)) {
        return prevSelected.filter((cat) => cat !== category);
      } else {
        return [...prevSelected, category];
      }
    });
  };

  const columns = 5;
  const chunkSize = Math.ceil(Object.keys(groupedCategories).length / columns);

  const chunkedCategories = [];

  let i = 0;
  for (const packageValue in groupedCategories) {
    if (i % chunkSize === 0) {
      chunkedCategories.push([]);
    }
    chunkedCategories[chunkedCategories.length - 1].push({ packageValue, categories: groupedCategories[packageValue] });
    i++;
  }

  const togglePackage = (packageValue) => {
    setExpandedPackages((prev) => ({
      ...prev,
      [packageValue]: !prev[packageValue],
    }));
  };

  const toggleSubpackage = (packageValue, subpackageValue) => {
    setExpandedSubpackages((prev) => ({
      ...prev,
      [packageValue]: {
        ...(prev[packageValue] || {}),
        [subpackageValue]: !(
          (prev[packageValue] || {})[subpackageValue] || false
        ),
      },
    }));
  };



  return (
    <div className="p-0 sm:p-6">
      <div className="mx-auto  max-w-2xl px-6 py-6 bg-gray-50 border-2 shadow-lg">
        <h1 className="text-center text-xl mb-4">Agregar Producto de Librería</h1>
        <div className="flex flex-col mb-3">
          <input
            type="text"
            onChange={(e) => setBooks({ ...books, title: e.target.value })}
            value={books.title}
            name="title"
            className="mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            placeholder="Nombre del Producto"
            style={{ width: "90%" }}
          />
        </div>
        <div className="flex flex-col mb-3">
          <label className="flex mb-4">Precio: </label>
          <input
            type="number"
            name="price"
            onChange={(e) => setBooks({ ...books, price: e.target.value })}
            value={books.price}
            className="mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            placeholder="Precio"
            style={{ width: "90%" }}
          />
        </div>


        <div className="flex flex-col mb-3">
          <input
            type="text"
            name="ref"
            onChange={(e) => setBooks({ ...books, ref: e.target.value })}
            value={books.ref}
            className="mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            placeholder="Ref"
            style={{ width: "90%" }}
          />
        </div>


        <div className="flex flex-col mb-3 text-sm">
          {Object.entries(groupedCategories).map(
            ([packageValue, subpackages]) => (
              <table
                key={packageValue}
                className="mx-auto w-3/4 text-center border-collapse border border-gray-500 mt-4"
              >
                <thead>
                  <tr>
                    <th
                      colSpan="3"
                      onClick={() => togglePackage(packageValue)}
                      className="cursor-pointer bg-cl1 text-white py-2 px-4"
                    >
                      {packageValue}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {expandedPackages[packageValue] &&
                    Object.entries(subpackages).map(
                      ([subpackageValue, categories]) => (
                        <React.Fragment key={subpackageValue}>
                          <tr>
                            <th
                              colSpan="3"
                              onClick={() =>
                                toggleSubpackage(packageValue, subpackageValue)
                              }
                              className="cursor-pointer bg-cl2 text-white py-2 px-4"
                            >
                              {subpackageValue}
                            </th>
                          </tr>
                          {expandedSubpackages[packageValue] &&
                            expandedSubpackages[packageValue][
                              subpackageValue
                            ] &&
                            categories.map((item) => (
                              <tr key={item.categoria}>
                                <td
                                  style={{
                                    width: "75%",
                                    backgroundColor: item.bcolor || "white",
                                    color: item.tcolor || "black",
                                    border: "1px solid #ccc",
                                    padding: "8px",
                                  }}
                                >
                                  {item.categoria}
                                </td>
                                <td
                                  style={{
                                    width: "25%",
                                    backgroundColor: "white",
                                    border: "1px solid #ccc",
                                    padding: "8px",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    id={item.categoria}
                                    checked={selectedCategories.includes(
                                      item.categoria
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(item.categoria)
                                    }
                                    className="mr-2"
                                  />
                                </td>
                              </tr>
                            ))}
                        </React.Fragment>
                      )
                    )}
                </tbody>
              </table>
            )
          )}
        </div>

        <div className="flex justify-center">
          <Link
            to="../"
            className="bg-cl1 hover:bg-cl2 text-white font-bold py-2.5 px-5 rounded m-2"
          >
            Regresar
          </Link>
          <button
            onClick={addBook}
            className="bg-cl1 hover-bg-cl2 text-white font-bold py-2 px-5 rounded m-2"
          >
            Agregar
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddLib