import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { NavLink, useNavigate } from "react-router-dom";
import * as FaIcons from "react-icons/fa";

const auth = getAuth();

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [username, setUsername] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUsername(user.displayName);

        user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.rol === "administrador") {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        });
      } else {
        setUsername(null);
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      //console.error("Error al cerrar sesión:", error);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className=" border-gray-200 py-2.5 bg-cl2">
      <div className="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto">
        <NavLink to="./" className="flex items-center">
          <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
            CODILEYES
          </span>
        </NavLink>
        <div className="flex items-center lg:order-2">
          <div className="hidden py-2 pl-3 pr-4 mr-4 sm:inline-block">
          {username && <span className="text-white">{username}</span>}
          </div>
          <div className="block py-2 pl-3 pr-4 text-white lg:p-0">
            {username && (
              <button className="text-white hover:text-black" onClick={handleLogout}>
                <FaIcons.FaSignOutAlt className="me-2 mt-1" />
              </button>
            )}
          </div>
          <button
            type="button"
            className={`inline-flex items-center p-2 ml-1 text-sm text-white rounded-lg lg:hidden hover:bg-cl1 focus:outline-none  ${
              menuOpen ? "bg-cl1" : ""
            }`}
            onClick={toggleMenu}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className={`w-6 h-6 ${menuOpen ? "hidden" : ""}`}
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg
              className={`w-6 h-6 ${!menuOpen ? "hidden" : ""}`}
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div
          className={`items-center justify-between w-full lg:flex lg:w-auto lg:order-1 ${
            menuOpen ? "block" : "hidden"
          } lg:block w-full lg:w-auto`}
          id="mobile-menu-2"
        >
          <div className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
            <NavLink
              to="./"
              className="block py-2 pl-3 pr-4 text-white border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0 "
            >
              Inventario
            </NavLink>
            <NavLink
              to="./budgets"
              className="block py-2 pl-3 pr-4 text-white border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0 "
            >
              Cotizaciones
            </NavLink>
            <NavLink
              to="./delivery"
              className="block py-2 pl-3 pr-4 text-white border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0 "
            >
              Pedidos
            </NavLink>
            {isAdmin && (
              <NavLink
                to="./manageUsers"
                className="block py-2 pl-3 pr-4 text-white border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0 "
              >
                Administrar Usuarios
              </NavLink>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;