import React from 'react';
import Lottie from 'react-lottie';
import animationData from './book.json';

// eslint-disable-next-line react/prop-types
export default function Loading({ size }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className='flex' style={{ width: size, height: size }}>
      <Lottie options={defaultOptions} height={size} width={size} />
    </div>
  );
}