import { Outlet, Route, Routes } from 'react-router-dom'

import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Inventory from './inventory/Inventory'
import Budgets from './budgets/Budgets'
import UserManage from './usersmanage/UsersManage'
import Footer from '../../components/footer/Footer'
import Create from '../../components/usersmanage/Create'
import Edit from '../../components/usersmanage/Edit'
import BudgetBook from '../../components/budgetBook/BudgetBook'
import Budget from '../../components/budget/Budget'
import AddBook from '../../components/book/AddBook'
import UpdateBook from '../../components/book/UpdateBook'
import AddCategory from '../../components/book/AddCategory'
import PrintCoti from '../../components/printCoti/PrintCoti'
import Delivery from './delivery/Delivery'
import PrintDelivery from '../../components/printDelivery/PrintDelivery'
import DeleteCategory from '../../components/book/DeleteCategory'
import { AuthGuardManageUsers } from '../../components/privacyrutes/AuthGuard'
import UpdateBudget from '../../components/book/UpdateBudget'
import UpdateDelivery from '../../components/book/UpdateDelivery'
import UpdateProcess from '../../components/book/UpdateProcess'
import Archive from './delivery/Archive'
import UpdatePerso from '../../components/book/UpdatePerso'
import PrintArchive from '../../components/printArchive/PrintArchive'
import UpdateFact from '../../components/book/UpdateFact'
import UpdateNamepack from '../../components/book/UpdateNamepack'
import UpdateCategory from '../../components/book/UpdateCategory'
import BudgetBookCat from '../../components/budgetBook/BudgetBookCat'
import AddLib from '../../components/book/AddLib'
import UpdateDevBudget from '../../components/book/UpdateDevBudget'
import BudPendiente from './budgets/BudPendiente'
import UpdateNamepack2 from '../../components/book/UpdateNamepack2'
import PrintBudPen from '../../components/printBudPen/PrintBudPen'
import UpdateBudPen from '../../components/book/UpdateBudPen'
import BudArchive from './budgets/BudArchive'
import PrintBudArchive from '../../components/PrintBudArchive/PrintBudArchive'
import Asana from './delivery/Asana'
import UpdateFact2 from '../../components/book/UpdateFact2'
import PrintDevAsana from '../../components/PrintDevAsana/PrintDevAsana'
import Can from './delivery/Can'
import PrintDevCan from '../../components/PrintDevCan/PrintDevCan'
import UpdateDevAsana from '../../components/book/UpdateDevAsana'

function Dashboard() {
  return (<div className='flex flex-col h-screen'>
    <Navbar />
    <div className="flex-1 overflow-y-auto p-5">
      <Routes>
        <Route path="/" element={<Outlet />}>
          {/* Rutas dentro del panel de administración */}
          <Route index element={<Inventory />} />
          <Route path="/addbook" element={<AddBook />} />
          <Route path="/addlib" element={<AddLib />} />
          <Route path="/updatebook" element={<UpdateBook />} />
          <Route path="/updatecategory" element={<UpdateCategory />} />
          <Route path="/updatebudget/:id" element={<UpdateBudget />} />
          <Route path="/updatebudpen/:id" element={<UpdateBudPen />} />
          <Route path="/updatedelivery" element={<UpdateDelivery />} />
          <Route path="/updateprocess" element={<UpdateProcess />} />
          <Route path="/updatedeliverybudget/:id" element={<UpdateDevBudget />} />
          <Route path="/updatedeliveryasana/:id" element={<UpdateDevAsana />} />
          <Route path="/updatefactura" element={<UpdateFact />} />
          <Route path="/updatefactura2" element={<UpdateFact2 />} />
          <Route path="/updatenamepack" element={<UpdateNamepack />} />
          <Route path="/updatenamepack2" element={<UpdateNamepack2 />} />
          <Route path="/updateperso/:id" element={<UpdatePerso />} />
          <Route path="/addcategory" element={<AddCategory />} />
          <Route path="/deletecategory" element={<DeleteCategory />} />
          <Route path="/budgets" element={<Budgets />} />
          <Route path="/budPendiente" element={<BudPendiente />} />
          <Route path="/budArchive" element={<BudArchive />} />
          <Route path="/budgetbook" element={<BudgetBook />} />
          <Route path="/budgetbookcat" element={<BudgetBookCat />} />
          <Route path="/budget" element={<Budget />} />
          <Route path="/printcoti/:id" element={<PrintCoti />} />
          <Route path="/printbudpen/:id" element={<PrintBudPen />} />
          <Route path="/printbudarchive/:id" element={<PrintBudArchive />} />
          <Route path="/printdelivery/:id" element={<PrintDelivery />} />
          <Route path="/printdevasana/:id" element={<PrintDevAsana />} />
          <Route path="/printdevcan/:id" element={<PrintDevCan />} />
          <Route path="/printarchive/:id" element={<PrintArchive />} />
          <Route path="/delivery" element={<Delivery />} />
          <Route path="/asana" element={<Asana />} />
          <Route path="/can" element={<Can />} />
          <Route path="/archive" element={<Archive />} />
          <Route path="/manageUsers" element={<AuthGuardManageUsers><UserManage /></AuthGuardManageUsers>} />
          <Route path="/manageUsers/create" element={<AuthGuardManageUsers><Create /></AuthGuardManageUsers>} />
          <Route path="/manageUsers/edit/:id" element={<AuthGuardManageUsers><Edit /></AuthGuardManageUsers>} />
        </Route>
      </Routes>
    </div>
    <Footer />
  </div>
  )
}

export default Dashboard