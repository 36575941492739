import { createSlice } from '@reduxjs/toolkit'
const initialState = JSON.parse(localStorage.getItem('budget')) ?? [];

const budgetSlice = createSlice({
    name: 'budget',
    initialState,
    reducers: {
        addToBudget(state, action)  {
            const { id } = action.payload
            const productInCartIndex = state.findIndex(item => item.id === id)
        
            if (productInCartIndex >= 0) {
              
              const newState = [
                ...state.slice(0, productInCartIndex),
                { ...state[productInCartIndex], quantity: state[productInCartIndex].quantity + 1 },
                ...state.slice(productInCartIndex + 1)
              ]
        
              
              return newState
            }
        
            const newState = [
              ...state,
              {
                ...action.payload, // product
                quantity: 1,
                lawcolor: ""
              }
            ]
        
            
            return newState
          },
            deleteFromBudget(state, action) {
            return state.filter(item => item.id !== action.payload.id);
        },
        clearCart(state, action) {
          return []; // Reset the cart to an empty array
      },
      removeFromBudget: (state, action) => {
        const { id } = action.payload;
        const productInCartIndex = state.findIndex(item => item.id === id);
  
        if (productInCartIndex >= 0 && state[productInCartIndex].quantity > 1) {
          const newState = [
            ...state.slice(0, productInCartIndex),
            { ...state[productInCartIndex], quantity: state[productInCartIndex].quantity - 1 },
            ...state.slice(productInCartIndex + 1),
          ];
          return newState;
        } else if (productInCartIndex >= 0 && state[productInCartIndex].quantity === 1) {
          // Si la cantidad es 1, elimina el artículo en lugar de restar 1
          const newState = [...state.slice(0, productInCartIndex), ...state.slice(productInCartIndex + 1)];
          return newState;
        }
  
        return state;
      },
    },
  });
    


export const { addToBudget, deleteFromBudget, clearCart, removeFromBudget } = budgetSlice.actions

export default budgetSlice.reducer;