import React, { useContext, useEffect } from 'react'
import MyContext from '../../context/data/myContext'
import { FaTrash, FaRegEdit } from "react-icons/fa";
import { Link } from 'react-router-dom'

function DeleteCategory() {
  const context = useContext(MyContext)
  const { ca1, deleteCategory, edithandle7, getCatData } = context

  useEffect(() => {
    getCatData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="relative overflow-x-auto max-w-screen-xl mx-auto my-5 pb-10">
      <div className='flex  mx-auto justify-end'>
        <Link to={'/dashboard'} className="focus:outline-none mr-0.5 text-white bg-cl1 border hover:bg-cl2 outline-0 font-medium rounded-lg text-sm px-5 py-2.5 mb-2">
          Regresar
        </Link>
      </div>
      <div className='realtive overflow-x-auto shadow-md'>
        <table className='w-full text-center'>
          <thead className='text-xs border border-cl2 text-white uppercase bg-cl1'>
            <tr>
              <th className='px-6 py-3'>Categoria</th>
              <th className='px-6 py-3'>Sub Paquete</th>
              <th className='px-6 py-3'>Paquete</th>
              <th className='px-6 py-3'>Acciones</th>
            </tr>
          </thead>
          {ca1.map((cat, index) => {
            return (
              <tbody>
                <tr className='bg-gray-50 border-b dark:border-gray-700'>
                  <td className='px-6 py-3 text-black'>{cat.categoria}</td>
                  <td className='px-6 py-3 text-black'>{cat.subpaquete}</td>
                  <td className='px-6 py-3 text-black'>{cat.paquete}</td>
                  <td className='px-6 py-3 text-black'>
                    <div className='inline-flex gap-2 cursor-pointer text-black justify-center'>
                      <FaTrash size={20} key={index} onClick={() => deleteCategory(cat)} />

                      <Link to={"../updatecategory"}>
                                  <FaRegEdit
                                    onClick={() => edithandle7(cat)}
                                    size={20}
                                    title="Editar"
                                  />
                                </Link>
                    </div>
                    
                  </td>
                </tr>
              </tbody>
            )

          })}
        </table>
      </div>
    </div>
  )
}

export default DeleteCategory