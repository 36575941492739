import React, { useContext, useEffect, useState } from "react";
import MyContext from "../../../context/data/myContext";
import { FaPrint, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import SearchBar4 from "../../../components/searchbar/SearchBar4";
import { collection, getCountFromServer, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { fireDB } from "../../../firebase/FirebaseConfig";
import Loading from "../../../components/Loadings/Loading";

function Archive() {
  const context = useContext(MyContext);
  const { deleteArchive,  filteredData4 } = context;

  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [docsPerPage] = useState(10); // Cantidad de documentos por página
  const [sortedArch, setSortedArch] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getArchData();
    // eslint-disable-next-line
  }, []);

  const [arch, setArch] = useState([]);

  const getArchData = async () => {
    const archRef = collection(fireDB, 'archive');
    setLoading(true);
    try {
      // Obtener todos los documentos de una sola vez
      const snapshot = await getDocs(query(archRef, orderBy('devID', 'desc')));
      const ordersArray = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setTotal(snapshot.size);
      setLoading(false);
  
      // Ordenar internamente después de la recuperación
      const sortedOrdersArray = ordersArray.sort((a, b) => {
        return sortDocuments(a.devID, b.devID);
      });
  
      setArch(sortedOrdersArray);
    } catch (error) {
      console.error("Error fetching archive documents:", error);
      setLoading(false);
    }
  };

  // Función auxiliar para ordenar documentos por devID
const sortDocuments = (devIDA, devIDB) => {
  const extractComponents = (devID) => {
    const parts = devID.split('-');
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const number = parseInt(parts[2], 10);
    return { year, month, number };
  };

  const aComp = extractComponents(devIDA);
  const bComp = extractComponents(devIDB);

  if (aComp.year !== bComp.year) {
    return bComp.year - aComp.year;
  } else if (aComp.month !== bComp.month) {
    return bComp.month - aComp.month;
  } else {
    return bComp.number - aComp.number;
  }
};


   // Definir un estado para almacenar la última referencia de documento recuperada
const [lastDocument, setLastDocument] = useState(null);

const handleLoadOneMore = async () => {
  try {
    let queryRef;
    if (lastDocument) {
      // Si hay un último documento, comenzar la consulta después de ese documento
      queryRef = query(
        collection(fireDB, 'archive'),
        startAfter(lastDocument),
        limit(1) // Obtener un solo documento adicional
      );
    } else {
      // Si no hay último documento, simplemente obtener el primer documento
      queryRef = query(
        collection(fireDB, 'archive'),
        limit(1) // Obtener un solo documento
      );
    }

    const snapshot = await getDocs(queryRef);
    const docsArray = snapshot.docs;

    if (docsArray.length > 0) {
      const nextDocument = docsArray[docsArray.length - 1];
      setLastDocument(nextDocument);
      const nextOrdersArray = docsArray.map(doc => ({ ...doc.data(), id: doc.id }));
      setArch(prevArch => [...prevArch, ...nextOrdersArray]);
    } else {
      console.log("No hay más documentos disponibles.");
    }
  } catch (error) {
    console.error("Error fetching additional archive documents:", error);
  }
};
  
  
  

  const handleDeleteConfirmation = (item) => {
    const isConfirmed = window.confirm(
      "¿Estás seguro de eliminar este elemento?"
    );
    if (isConfirmed) {
      deleteArchive(item);
    }
  };

  useEffect(() => {
    const sortedData = [...arch].sort((a, b) => {
      // Función para extraer el año, mes y número del devID
      const extractComponents = (devID) => {
        const parts = devID.split('-');
        const year = parseInt(parts[0]);
        const month = parseInt(parts[1]);
        const number = parseInt(parts[2]);
        return { year, month, number };
      };

      // Extraer componentes de devID de cada documento
      const devIDA = extractComponents(a.devID);
      const devIDB = extractComponents(b.devID);

      // Comparar primero por año, luego por mes y por último por número
      if (devIDA.year !== devIDB.year) {
        return devIDB.year - devIDA.year; // Ordenar por año descendente
      } else if (devIDA.month !== devIDB.month) {
        return devIDB.month - devIDA.month; // Ordenar por mes descendente
      } else {
        return devIDB.number - devIDA.number; // Ordenar por número descendente
      }
    });
    setSortedArch(sortedData);
  }, [arch]);

  // Función para cambiar de página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calcular índices de inicio y fin de documentos
  const indexOfLastDoc = currentPage * docsPerPage;
  const indexOfFirstDoc = indexOfLastDoc - docsPerPage;
  const currentDocs = sortedArch.slice(indexOfFirstDoc, indexOfLastDoc);

  

  return (
    <div className="relative overflow-x-auto max-w-screen-xl mx-auto my-4 shadow-md font-rubik ">
      <div className="">
        <div className="text-center py-5">
          <div className="w-full flex justify-end">
            <div className="flex px-6  justify-start mx-0.5 border border-gray-400 py-2 rounded">
              <SearchBar4 />
            </div>
            <div className="flex-grow"></div>
            <Link
              to="../delivery"
              className="bg-cl1 hover-bg-cl2 text-white py-2.5 px-6 rounded"
            >
              Regresar
            </Link>

            {/* {<button onClick={handleLoadOneMore} className="bg-cl1 hover-bg-cl2 text-white py-2.5 px-6 rounded ml-2">
                <h1>
                  Load one more of {total}
                </h1>
            </button>} */}
          </div>
        </div>

        

        {filteredData4.length === 0 ? (
          <table className="w-full text-center mb-10">
            

            {loading ? (
            <div className="flex justify-center items-center">
            <Loading size={250} />
          </div>
        ) : (
          <>
          <thead className="text-xs border border-cl2 text-white uppercase bg-cl1">
              <tr>
                <th className="px-6 py-3">No. de Pedido</th>
                <th className="px-6 py-3">Nombre</th>
                <th className="px-6 py-3">Precio Total</th>
                <th className="px-6 py-3">Pos File</th>
                <th className="px-6 py-3">Acciones</th>
              </tr>
            </thead>
          {currentDocs.map((item, index) => (
            <tbody key={index}>
              <tr className="bg-gray-50 border-b dark:border-gray-700">
                <td className="px-6 py-3 text-black">{item.devID}</td>
                <td className="px-6 py-3 text-black">
                  {item.delivery.cot3.name}
                </td>
                <td className="px-6 py-3 text-black">
                  Q {item.delivery.cot3.grandTotal}
                </td>
                <td className="px-6 py-3 text-black">
                  # {item.delivery.factura}
                </td>
                <td className="px-6 py-3 text-black">
                  <div className="inline-flex justify-center gap-2">
                    <div>
                      <FaTrash
                        size={20}
                        onClick={() => handleDeleteConfirmation(item)}
                      />
                    </div>
                    <div>
                      <Link to={`../printarchive/${item.id}`}>
                        <FaPrint size={20} title="Imprimir" />
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          ))}
          </>
        )}

            
          </table>
        ) : (
          <table className="w-full text-center mb-10">
            <thead className="text-xs border border-cl2 text-white uppercase bg-cl1">
              <tr>
                <th className="px-6 py-3">No. de Pedido</th>
                <th className="px-6 py-3">Nombre</th>
                <th className="px-6 py-3">Precio Total</th>
                <th className="px-6 py-3">Pos File</th>
                <th className="px-6 py-3">Acciones</th>
              </tr>
            </thead>

            {filteredData4.map((item, index) => (
              <tbody key={index}>
                <tr className="bg-gray-50 border-b dark:border-gray-700">
                  <td className="px-6 py-3 text-black">{item.devID}</td>
                  <td className="px-6 py-3 text-black">
                    {item.delivery.cot3.name}
                  </td>
                  <td className="px-6 py-3 text-black">
                    Q {item.delivery.cot3.grandTotal}
                  </td>
                  <td className="px-6 py-3 text-black">
                    # {item.delivery.factura}
                  </td>
                  <td className="px-6 py-3 text-black">
                    <div className="inline-flex justify-center gap-2">
                      <div>
                        <FaTrash
                          size={20}
                          onClick={() => handleDeleteConfirmation(item)}
                        />
                      </div>
                      <div>
                        <Link to={`../printarchive/${item.id}`}>
                          <FaPrint size={20} title="Imprimir" />
                        </Link>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        )}

        {/* Controles de paginación */}
        <div className="flex  justify-center mt-4">
          <ul className="flex overflow-x-auto max-w-screen-xl w-full">
            {Array.from({ length: Math.ceil(sortedArch.length / docsPerPage) }).map((_, index) => (
              <li key={index}>
                <button className="mx-1 px-3 py-1 rounded bg-gray-200 text-gray-700" onClick={() => paginate(index + 1)}>
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Archive;

