import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore} from 'firebase/firestore';
import 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB_rt48LYQS91F7eGj34UrZw1XdOMeEIX8",
  authDomain: "codileyes.firebaseapp.com",
  projectId: "codileyes",
  storageBucket: "codileyes.appspot.com",
  messagingSenderId: "12377355059",
  appId: "1:12377355059:web:a60ce3340812e31ecec446"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const fireDB = getFirestore(app);
const storage = getStorage(app);

export default app
export {fireDB, storage};