import React, { useContext, useEffect, useState } from "react";
import MyContext from "../../context/data/myContext";
import Modal from "../modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFromBudget,
  addToBudget,
  clearCart,
  removeFromBudget,
} from "../../redux/budgetSlice";
import { toast } from "react-toastify";
import { doc, getDoc, setDoc, increment, runTransaction } from "firebase/firestore";
import { fireDB } from "../../firebase/FirebaseConfig";
import { Link, useNavigate } from "react-router-dom";
import { onAuthStateChanged, getAuth } from "firebase/auth";

const auth = getAuth();

function Budget() {
  const [lastAssignedId, setLastAssignedId] = useState(0);
  const context = useContext(MyContext);
  const { getOrderData } = context;
  const dispatch = useDispatch();

  const [username, setUsername] = useState(null);
  const [iniciales, setIniciales] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUsername(user.displayName);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (username) {
      const nombres = username.split(" ");
      let iniciales = "";

      for (let i = 0; i < nombres.length; i++) {
        if (nombres[i].length > 0) {
          iniciales += nombres[i][0].toUpperCase();
        }
      }

      setIniciales(iniciales);
    }
  }, [username]);

  const budgetItems = useSelector((state) => state.budget);

  const addBudget = (product) => {
    dispatch(addToBudget(product));
    toast.success("+1", {
      position: toast.POSITION.TOP_LEFT,
    });
  };

  const minusBudget = (product) => {
    dispatch(removeFromBudget(product));
    toast.success("-1", {
      position: toast.POSITION.TOP_LEFT,
    });
  };

  const deleteCart = (item) => {
    dispatch(deleteFromBudget(item));
    toast.success("Eliminado", {
      position: toast.POSITION.TOP_LEFT,
    });
  };

  // Definición de la función fetchLastAssignedId
  /*
  const fetchLastAssignedId = async () => {
    const docRef = doc(fireDB, "metaData", "lastAssignedId");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const newLastAssignedId = docSnap.data().lastId;
      //console.log("New lastAssignedId:", newLastAssignedId);
      setLastAssignedId(newLastAssignedId);
    }
  };
  */

  useEffect(() => {
    localStorage.setItem("budget", JSON.stringify(budgetItems));
  }, [budgetItems]);

  const [totalAmount, setTotalAmount] = useState(0);
  useEffect(() => {
    let temp = 0;
    budgetItems.forEach((budgetItem) => {
      temp =
        temp + parseFloat(budgetItem.price) * parseInt(budgetItem.quantity);
    });
    setTotalAmount(temp);
  }, [budgetItems]);

  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [newshipping, setNewshipping] = useState(0);

  const grandTotal2 = totalAmount + Number(newshipping) - discountPercentage;

  const grandTotal = parseFloat(grandTotal2.toFixed(2));

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [media, setMedia] = useState("");
  const [nameport, setNameport] = useState("");
  const [logo, setLogo] = useState("");
  const [namepack, setNamepack] = useState("");
  const [bcolor, setBcolor] = useState("");
  const [tcolor, setTcolor] = useState("");

  const buyNow = async () => {
    // Generar un retraso aleatorio entre 0 y 1 segundo
    const randomDelay = Math.random() * 5000; // Convertir de segundos a milisegundos
    await new Promise(resolve => setTimeout(resolve, randomDelay));
  
    if (name === "" || phoneNumber === "") {
      return toast.error("All fields are required", {
        position: "top-left",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  
    const docRef = doc(fireDB, "metaData", "lastAssignedId");
  
    try {
      // Utilizar una transacción para garantizar la atomicidad de la operación de incremento
      await runTransaction(fireDB, async (transaction) => {
        const docSnap = await transaction.get(docRef);
        const newLastAssignedId = docSnap.data().lastId + 1;
        transaction.update(docRef, { lastId: newLastAssignedId });
  
        const newId = newLastAssignedId.toString().padStart(3, "0");
  
        const orderInfo = {
          iniciales,
          budgetItems,
          name,
          phoneNumber,
          media,
          nameport,
          logo,
          grandTotal,
          newshipping,
          discountPercentage,
          cotId: newId,
          namepack,
          tcolor,
          bcolor,
          date: new Date().toLocaleString("es-ES", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          }),
        };
  
        // Guardar la cotización en un nuevo documento con el nuevo identificador
        await setDoc(doc(fireDB, "budget", newId), { ...orderInfo });
        toast.success("Cotizacion Guardada", {
          position: toast.POSITION.TOP_LEFT,
        });
        await getOrderData();
  
        setName("");
        setPhoneNumber("");
        setMedia("");
        setIniciales("");
        setNamepack("");
        setBcolor("");
        setTcolor("");
        setNameport("");
        setLogo("");
        setNewshipping("");
        setDiscountPercentage("");
  
        dispatch(clearCart());
  
        navigate("../budgets");
  
        localStorage.removeItem("budget");
      });
    } catch (error) {
      console.log(error);
      // Manejar el error de la transacción aquí
    }
  };
  

  const handleInputChange = (value, setter) => {
    if (/^\d+(\.\d{0,2})?$/.test(value)) {
      setter(value);
    }
  };

  return (
    <div className="h-screen bg-gray-100 pt-5 mb-[60%] ">
      <div className="grid grid-cols-1 w-3/5 place-items-end py-4  mx-auto">
        <Link
          to={"../budgets"}
          className="focus:outline-none mr-14 text-white bg-cl1  border hover:bg-cl2 outline-0 font-medium rounded-lg text-sm px-5 py-2.5 mb-2"
        >
          Regresar
        </Link>
      </div>
      <div className="mx-auto max-w-5xl justify-center px-6 md:flex md:space-x-6 xl:px-0 ">
        <div className="rounded-lg md:w-2/3 ">
          {budgetItems.map((item) => {
            const { title, price, id, ref } = item;
            return (
              <div className="justify-between mb-6 rounded-lg border  drop-shadow-xl bg-white p-6  sm:flex  sm:justify-start">
                <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                  <div className="mt-5 sm:mt-0">
                    <h2 className="text-lg font-bold text-gray-900">{title}</h2>
                    <h2 className=" text-sm font-bold text-gray-900">{ref}</h2>
                    <button
                      className="text-3xl font-extrabold text-cl1"
                      key={id}
                      onClick={() => minusBudget(item)}
                    >
                      -
                    </button>
                    <small className="text-lg"> {item.quantity} </small>
                    <button
                      className="text-3xl font-extrabold text-cl1"
                      key={id}
                      onClick={() => addBudget(item)}
                    >
                      +
                    </button>
                    <p className="mt-1 text-lg font-semibold text-gray-700">
                      Q{price}
                    </p>
                  </div>
                  <div
                    onClick={() => deleteCart(item)}
                    className="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className=" mt-6 h-full rounded-lg border bg-white p-6 shadow-md md:mt-0 md:w-1/3">
          <div className="mb-2 flex justify-between">
            <p className="text-gray-700">Subtotal</p>
            <p className="text-gray-700">Q{totalAmount}</p>
          </div>

          <div className="mb-2 flex justify-between">
            <p className="text-gray-700">Envio</p>
            <input
              value={newshipping}
              onChange={(e) =>
                handleInputChange(e.target.value, setNewshipping)
              }
              className="bg-zinc-200 text-right"
              type="text"
            />
          </div>

          <div className="mb-2 flex justify-between">
            <p className="text-gray-700">Descuento</p>
            <input
              value={discountPercentage}
              onChange={(e) =>
                handleInputChange(e.target.value, setDiscountPercentage)
              }
              className="bg-zinc-200 text-right"
              type="text"
            />
          </div>

          <hr className="my-4" />
          <div className="flex justify-between mb-3">
            <p className="text-lg font-bold">Total</p>
            <div className>
              <p className="mb-1 text-lg font-bold">Q{grandTotal}</p>
            </div>
          </div>
          <Modal
            name={name}
            phoneNumber={phoneNumber}
            media={media}
            setName={setName}
            setMedia={setMedia}
            setPhoneNumber={setPhoneNumber}
            buyNow={buyNow}
          />
        </div>
      </div>
    </div>
  );
}

export default Budget;
