import React, { useContext } from 'react'
import MyContext from '../../context/data/myContext';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

function SearchBar6() {
    const context = useContext(MyContext)
    const { filteredData6, wordEntered6, handleFilter6, clearInput6 } = context
  return (
    <div>
        <div className="ml-auto flex items-center">
              <input type="text" placeholder='Buscar...' value={wordEntered6} onChange={handleFilter6} style={{outline: 'none'}} className='bg-gray-100'/>
                <div>
                    {filteredData6.length === 0 ? (<SearchIcon/>) : (<CloseIcon  id="clearBtn" onClick={clearInput6} />) }
                </div>
              </div>
      
    </div>
  )
}

export default SearchBar6