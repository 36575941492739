import React from 'react';

function Footer() {
    return (
        <footer className="bg-cl1 p-4 text-center absolute bottom-0 w-full text-white">
            <p>&copy; {new Date().getFullYear()} CodiLeyes</p>
        </footer>
    );
}

export default Footer;