import React, { useState, useEffect } from 'react';
import app from "../../firebase/FirebaseConfig"
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth"
import { useNavigate } from 'react-router-dom';
const auth = getAuth(app)


function Home() {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate()

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    const email = `${username}@page.com`;
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setError('Credenciales Incorrectas');
    } 
  };

  const handlePasswordKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();  // Llama a la función de inicio de sesión cuando se presiona Enter
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.rol === 'administrador') {
            navigate('./dashboard')
            setIsAdmin(true);
          } else {
            navigate('./dashboard')
            setIsAdmin(false);
          }
        });
      } else {
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return (
    <div className="bg-cl1 h-screen w-screen">
      <div className="flex flex-col items-center flex-1 h-full justify-center px-4 sm:px-0">
        <div
          className="flex rounded-lg shadow-lg w-full sm:w-3/4 lg:w-1/2 bg-white sm:mx-0"
          style={{ height: '500px' }}>

          <div className="flex flex-col w-full md:w-1/2 p-4">
            <div className="flex flex-col flex-1 justify-center mb-8">
              <h1 className="text-4xl text-center font-thin">Bienvenido</h1>
              <div className="w-full mt-4">
                <div className="form-horizontal w-3/4 mx-auto" >
                  <div className="flex flex-col mt-4">
                    <input
                      type="text"
                      id="username"
                      value={username}
                      onChange={handleUsernameChange}
                      required
                      className='flex-grow h-8 px-2 border rounded border-grey-400'
                      placeholder='Usuario'
                    />
                  </div>
                  <div className="flex flex-col mt-4">
                    <input
                      type="password"
                      id="password"
                      value={password}
                      onChange={handlePasswordChange}
                      onKeyPress={handlePasswordKeyPress}
                      required
                      className='flex-grow h-8 px-2 rounded border border-grey-400'
                      placeholder='Contraseña'
                    />
                  </div>
                  <div className="flex flex-col mt-8">
                    <button className='bg-blue-500 hover:bg-blue-700 text-white text-sm font-semibold py-2 px-4 rounded' onClick={handleLogin}>
                      Iniciar Sesión
                    </button>
                    {error && <p>{error}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="hidden md:block md:w-1/2 rounded-r-lg"
            style={{
              background: `url('https://dhgf5mcbrms62.cloudfront.net/65380342/cover-73NuwU/CMtUV7R-1200x1200.jpg')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center'
            }}
          ></div>

        </div>
      </div>
    </div >
  )
}

export default Home