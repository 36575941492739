import React, { useContext } from "react";
import MyContext from "../../context/data/myContext";
import { Link } from "react-router-dom";

function UpdateDelivery() {
  const context = useContext(MyContext);
  const { devi, setDevi, updateDelivery } = context;
  return (
    <div className="p-0 sm:p-6">
      <div className=" mx-auto max-w-sm px-6 py-6 bg-gray-50 border-2 shadow-lg">
        <h1 className="text-center text-xl mb-4">Actualizar Pedido</h1>
        <div className="flex flex-col mb-3">
          <label
            htmlFor="address"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Direccion
          </label>
          <input
            type="text"
            value={devi.address}
            onChange={(e) => setDevi({ ...devi, address: e.target.value })}
            name="address"
            className="mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            placeholder="Direccion"
            style={{ width: "90%" }}
          />
        </div>

        <div>
    <label
        htmlFor="datesend"
        className="block mb-2 text-sm font-medium text-gray-900"
    >
        Fecha de Envio : {devi.datesend}
    </label>
    <input
        type="date"
        value={devi.datesend}
        onChange={(e) => {
            const dateString = e.target.value;
            const dateObject = new Date(dateString);
            const day = dateObject.getUTCDate();
            const month = dateObject.getUTCMonth() + 1;
            const year = dateObject.getUTCFullYear();
            const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
            setDevi({ ...devi, datesend: formattedDate });
        }}
        name="datesend"
        id="datesend"
        lang="es"
        className="border outline-0 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100"
        required
    />
</div>


        <div className="flex flex-col mb-3">
          <label
            htmlFor="nit"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            NIT
          </label>
          <input
            type="text"
            value={devi.nit}
            onChange={(e) => setDevi({ ...devi, nit: e.target.value })}
            name="nit"
            className="mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            placeholder="NIT"
            style={{ width: "90%" }}
          />
        </div>

        <div className="flex flex-col mb-3">
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Correo Electronico
          </label>
          <input
            type="text"
            value={devi.email}
            onChange={(e) => setDevi({ ...devi, email: e.target.value })}
            name="email"
            className="mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            placeholder="Correo electronico"
            style={{ width: "90%" }}
          />
        </div>

        <div className="flex flex-col mb-3">
          <label
            htmlFor="send"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Metodo de Envio : {devi.send}
          </label>
          <select
            id={devi.send}
            onChange={(e) => setDevi({ ...devi, send: e.target.value })}
            className="mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            style={{ width: "90%" }}
          >
            <option value="">Metodo de Envio</option>
            <option value="Mensajeria Propia">Mensajeria Propia</option>
            <option value="CAEX">CAEX</option>
            <option value="FORZA">FORZA</option>
            <option value="GUATEX">GUATEX</option>
            <option value="Entrega en Tienda">Entrega en Tienda</option>
          </select>
        </div>

        <div className="flex flex-col mb-3">
          <label
            htmlFor="eshop"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            #Eshop
          </label>
          <input
            type="text"
            value={devi.eshop}
            onChange={(e) => setDevi({ ...devi, eshop: e.target.value })}
            name="envio"
            className="mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            placeholder="Numero de Eshop"
            style={{ width: "90%" }}
          />
        </div>

        <div className="flex flex-col mb-3">
          <label
            htmlFor="pay"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Metodo de Pago
          </label>
          <select
            id={devi.pay}
            onChange={(e) => setDevi({ ...devi, pay: e.target.value })}
            className="mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            style={{ width: "90%" }}
          >
            <option value="">Metodo de pago</option>
            <option value="Efectivo">Efectivo</option>
            <option value="Tarjeta">Tarjeta</option>
            <option value="Transferencia">Transferencia</option>
            <option value="Deposito">Deposito</option>
          </select>
        </div>

        <div className="flex flex-col mb-3">
          <label
            htmlFor="obs"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Observacion del Pedido
          </label>
          <input
            type="text"
            value={devi.obs}
            onChange={(e) => setDevi({ ...devi, obs: e.target.value })}
            name="obs"
            className="mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            placeholder="Observacion"
            style={{ width: "90%" }}
          />
        </div>

        <div className="flex flex-col mb-3">
          <label
            htmlFor="notes"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Observacion del Estado
          </label>
          <textarea
            type="text"
            value={devi.notes}
            onChange={(e) => setDevi({ ...devi, notes: e.target.value })}
            name="notes"
            className="mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            placeholder="Observacion"
            style={{ width: "90%" }}
          />
        </div>

        <div className=" flex justify-center mb-3">
          <Link
            to="../delivery"
            className="bg-cl1  hover:bg-cl2 text-white font-bold py-2.5 px-4  rounded m-2"
          >
            Regresar
          </Link>
          <button
            onClick={updateDelivery}
            className="bg-cl1  hover:bg-cl2 text-white font-bold py-2 px-4 rounded m-2"
          >
            Actualizar
          </button>
        </div>
      </div>
    </div>
  );
}

export default UpdateDelivery;
