import React, { useContext } from 'react'
import MyContext from '../../context/data/myContext';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

function SearchBar5() {
    const context = useContext(MyContext)
    const { filteredData5, wordEntered5, handleFilter5, clearInput5 } = context
  return (
    <div>
        <div className="ml-auto flex items-center">
              <input type="text" placeholder='Buscar...' value={wordEntered5} onChange={handleFilter5} style={{outline: 'none'}} className='bg-gray-100'/>
                <div>
                    {filteredData5.length === 0 ? (<SearchIcon/>) : (<CloseIcon  id="clearBtn" onClick={clearInput5} />) }
                </div>
              </div>
      
    </div>
  )
}

export default SearchBar5