import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const auth = getAuth();

export const AuthGuard = ({ children }) => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
                navigate('/');
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    return isAuthenticated ? <>{children}</> : null;
};


export const AuthGuardManageUsers = ({ children }) => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const idTokenResult = await user.getIdTokenResult();
                if (idTokenResult.claims.rol === 'administrador') {
                    setIsAdmin(true);
                }
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
                navigate('/');
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {
        if (!isAdmin && isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, isAdmin, navigate]);

    return isAuthenticated && isAdmin ? <>{children}</> : null;
};





