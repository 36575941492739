import React, { useContext } from 'react'
import MyContext from '../../context/data/myContext';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';


function SearchBar() {
    const context = useContext(MyContext)
    const { filteredData, wordEntered, handleFilter, clearInput } = context
  return (
    <div>
        <div className="ml-auto flex items-center">
              <input type="text" placeholder='Buscar...' value={wordEntered} onChange={handleFilter} style={{outline: 'none'}} className='bg-gray-100'/>
                <div>
                    {filteredData.length === 0 ? (<SearchIcon/>) : (<CloseIcon  id="clearBtn" onClick={clearInput} />) }
                </div>
              </div>
      
    </div>
  )
}

export default SearchBar
