import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './pages/home/Home'
import MyState from './context/data/myState'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import Login from "./pages/login/Login"
import { AuthGuard } from "./components/privacyrutes/AuthGuard";
import Dashboard from "./pages/dashboard/Dashboard"


function App() {
  return (
    
      <div className="font-rubik bg-gray-100">
        <Router>
        <MyState>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/dashboard/*"
              element={
                <AuthGuard>
                  <Dashboard />
                </AuthGuard>
              }
            />
          </Routes>
          <ToastContainer />
          </MyState>
        </Router>
      </div>
    
  );
}

export default App;
