import React, { useContext } from "react";
import MyContext from "../../context/data/myContext";
import { Link } from "react-router-dom";

function UpdateProcess() {
  const context = useContext(MyContext);
  const {  proc, setProc, updateProcess } = context;
  return (
    <div className="p-0 sm:p-6">
      <div className=" mx-auto max-w-sm px-6 py-6 bg-gray-50 border-2 shadow-lg">
        <h1 className="text-center text-xl mb-4">Actualizar Estado</h1>
        <h2 className="text-center text-sm mb-4">Click 2 veces</h2>
        <div className="flex flex-col mb-3">
        <select
            id={proc.process}
            onChange={(e) => setProc({ ...proc, process: e.target.value })}
            className="mx-auto px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            style={{ width: "90%" }}
          >
            <option value="">Elige Estado</option>
            <option value="EN PROCESO">EN PROCESO</option>
            <option value="SUBIDO A ASANA">SUBIDO A ASANA</option>
            <option value="CANCELADO">CANCELADO</option>
            
          </select>
        </div>
        

        <div className=" flex justify-center mb-3">
        <Link
            to="../delivery"
            className="bg-cl1  hover:bg-cl2 text-white font-bold py-2.5 px-4  rounded m-2"
          >
            Regresar
          </Link>
          <button
             onClick={() => updateProcess(proc)}
            className="bg-cl1  hover:bg-cl2 text-white font-bold py-2 px-4 rounded m-2"
          >
            Actualizar
          </button>
        </div>
      </div>
    </div>
  );
}

export default UpdateProcess;
