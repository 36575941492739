import React, { useState, useEffect } from 'react';
import app from "../../firebase/FirebaseConfig"
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth"
import { useNavigate } from 'react-router-dom';
const auth = getAuth(app)


const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate()

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    const email = `${username}@page.com`;
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setError('Credenciales Incorrectas');
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.rol === 'administrador') {
            navigate('/dashboard')
            setIsAdmin(true);
          } else {
            navigate('/dashboard')
            setIsAdmin(false);
          }
        });
      } else {
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return (
    <div className='flex justify-center items-center h-screen bg-gray-200'>
        <div className='mx-auto max-w-md px-20 py-12 bg-white border-0 shadow-lg sm:rounded-3xl'>
          <h2 className='text-2x1 font-bold mb-8'>Iniciar Sesión</h2>
          <div className='flex flex-col'>
            <label className='leading-loose' htmlFor="email">Correo Electrónico:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={handleUsernameChange}
              required
              className='px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600'
            />
          </div>
          <div  className='flex flex-col'>
            <label className='leading-loose' htmlFor="password">Contraseña:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              required
              className='px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600'
            />
          </div>
          <button className='bg-cl1 hover:bg-cl2 text-white font-bold py-2 px-6 rounded m-2' onClick={handleLogin}>Iniciar Sesión</button>
          {error && <p>{error}</p>}
          {isAdmin && <p>Eres un administrador.</p>}
        </div>
      </div>
    
  );
};

export default Login;
