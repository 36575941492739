import React, { useContext } from 'react'
import MyContext from '../../context/data/myContext';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

function SearchBar3() {
    const context = useContext(MyContext)
    const { filteredData3, wordEntered3, handleFilter3, clearInput3 } = context
  return (
    <div>
        <div className="ml-auto flex items-center">
              <input type="text" placeholder='Buscar...' value={wordEntered3} onChange={handleFilter3} style={{outline: 'none'}} className='bg-gray-100'/>
                <div>
                    {filteredData3.length === 0 ? (<SearchIcon/>) : (<CloseIcon  id="clearBtn" onClick={clearInput3} />) }
                </div>
              </div>
      
    </div>
  )
}

export default SearchBar3